import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// rem适配
import './utils/rem';

import 'element-ui/lib/theme-chalk/index.css';// element按需引入
import {
  Collapse,
  CollapseItem,
  Carousel,
  CarouselItem,
  Message,
} from 'element-ui';
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.prototype.$message = Message;

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
