<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
  import ResizeMixin from './utils/homeResize'
  import { mapGetters } from 'vuex'
  // 添加事件监听器禁止页面滚动
  // document.body.addEventListener('touchstart', preventTouch, { passive: false });
  document.body.addEventListener('touchmove', preventTouch, { passive: false });

  function preventTouch(event) {
    event.preventDefault();
  }
  export default ({
    mixins: [ResizeMixin],
    mounted() {
      // 主题切换
      document.getElementsByTagName('html')[0].setAttribute('data-theme', this.theme)
    },
    computed: {
      ...mapGetters(['theme']),
    },
    watch: {
      // 主题切换
      theme: (now) => {
        document.getElementsByTagName('html')[0].setAttribute('data-theme', now)
      },
    },
  })
</script>

<style lang="less">
  @import url('@/style/common.less');
</style>