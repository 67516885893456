const state = {
  // 主题
  theme: localStorage.getItem('riserTheme') || 'light',// light dark
  // 当前选中的导航
  activeNav: localStorage.getItem('riserNav') || 0,
  device: 'desktop',// desktop mobile
  // 菜单栏
  isOpen: false,
  // 导航栏
  navList: [
    { name: 'Home', pageNum: 0, pasgeSize: 1 },
    { name: 'Introduction', pageNum: 1, pasgeSize: 2 },
    { name: 'CroeBusiness', pageNum: 3, pasgeSize: 1 },
    { name: 'ServiceMode', pageNum: 4, pasgeSize: 1 },
    { name: 'Partners', pageNum: 5, pasgeSize: 1 },
    { name: 'Contact', pageNum: 6, pasgeSize: 1 },
  ],
}

const mutations = {
  SET_THEME: (state, theme) => {
    state.theme = theme
  },
  SET_ACTIVE_NAV: (state, activeNav) => {
    state.activeNav = activeNav
  },
  // 分辨率判定
  TOGGLE_DEVICE: (state, device) => {
    state.device = device
  },
  SET_IS_OPEN: (state, isOpen) => {
    state.isOpen = isOpen
  },
}

const actions = {
  // 主题
  steTheme({ commit }, theme) {
    localStorage.setItem('riserTheme', theme);
    commit('SET_THEME', theme);
  },
  // 当前导航选中页
  steActiveNav({ commit }, activeNav) {
    localStorage.setItem('riserNav', activeNav);
    commit('SET_ACTIVE_NAV', activeNav);
  },
  // 分辨率判定
  toggleDevice({ commit }, device) {
    commit('TOGGLE_DEVICE', device)
  },
  setIsOpen({ commit }, isOpen) {
    commit('SET_IS_OPEN', isOpen)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
